






























import {
  defineComponent,
  ref,
  onMounted,
  onUnmounted,
} from '@vue/composition-api'
import { Keyfact } from '@/inc/types'
import lottie, { AnimationItem } from 'lottie-web'

// Bodymovin JSON files
import communication from '@/assets/json/communication.json'
import gouvernance from '@/assets/json/gouvernance.json'
import veille from '@/assets/json/veille.json'
import transformation from '@/assets/json/transformation.json'

const bodymovins = [
  {
    data: communication,
    slug: 'communication',
    step: 200,
  },
  {
    data: veille,
    slug: 'veille',
    step: 100,
  },
  {
    data: gouvernance,
    slug: 'gouvernance',
    step: 160,
  },
  {
    data: transformation,
    slug: 'transformation',
    step: 40,
  },
]

const lotties = [] as AnimationItem[]

const loadLottie = (obj, root) => {
  if (!root) {
    return
  }

  const container = root.querySelector(
    `[data-slug="${obj.slug}"]`
  ) as SVGSVGElement

  const anim = lottie.loadAnimation({
    container,
    renderer: 'svg',
    loop: true,
    autoplay: true,
    animationData: obj.data,
  }) as AnimationItem

  lotties.push(anim)
}

export default defineComponent({
  name: 'keyfacts-item',
  props: {
    content: {
      type: Object as () => Keyfact,
      default: {} as Keyfact,
    },
    index: {
      type: Number,
      default: 0,
    },
  },

  components: {
    // LottiePlayer,
  },

  setup(props) {
    const isMounted = ref<boolean>(false)
    const bodymovin = bodymovins[props.index]

    const generateLayers = () => {
      setTimeout(() => {
        loadLottie(bodymovin, document)
      }, 1000 * (props.index + 1))
    }

    const destroyActiveLotties = () => {
      lotties.forEach(l => l.destroy())
    }

    const init = () => {
      generateLayers()
    }

    const destroy = () => {
      destroyActiveLotties()
    }

    onMounted(() => {
      init()

      isMounted.value = true
    })

    onUnmounted(() => {
      destroy()
    })

    return {
      isMounted,
    }
  },
})
