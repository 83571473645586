
































import { defineComponent } from '@vue/composition-api'
import { Keyfacts } from '@/inc/types'

import KeyfactsItem from './Item.vue'

export default defineComponent({
  name: 'keyfacts-grid',
  props: {
    content: {
      type: Array as () => Keyfacts,
      default: [] as Keyfacts,
    },
  },

  components: {
    KeyfactsItem,
  },
})
