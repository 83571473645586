


































import {
  defineComponent,
  ref,
  onMounted,
  onUnmounted,
} from '@vue/composition-api'
import { gsap } from 'gsap'
import Slidy, { TransitionInfos } from 'epic-slidy'
import { SlideBis } from '@/inc/types'

export default defineComponent({
  name: 'slider-slidy',
  props: {
    slides: {
      type: Array as () => SlideBis[],
      default: [],
    },
  },

  setup() {
    let slider: Slidy
    const list = ref<null | HTMLElement>(null)

    onMounted(() => {
      const transition = (
        currentSlide: HTMLElement | HTMLElement[],
        newSlide: HTMLElement | HTMLElement[],
        infos: TransitionInfos
      ) => {
        console.log(infos)

        return gsap
          .timeline()

          .add('transition')
          .set(newSlide, {
            opacity: 0,
            zIndex: 3,
          })
          .to(
            currentSlide,
            {
              duration: 0.5,
              opacity: 0,
              ease: 'power4.inOut',
            },
            'transition'
          )
          .to(
            newSlide,
            {
              duration: 0.5,
              opacity: 1,
              ease: 'power4',
            },
            'transition'
          )
          .then()
      }
      slider = new Slidy(list.value as HTMLElement, {
        click: true,
        pagination: true,
        transition,
      })

      slider.init()
    })

    onUnmounted(() => {
      slider.destroy()
    })

    return {
      list,
    }
  },
})
