

















































































































































































































































import {
  defineComponent,
  ref,
  onMounted,
  onUnmounted,
} from '@vue/composition-api'
import { Flickity } from '@/inc/types'

export default defineComponent({
  name: 'slider-flickity',
  props: {
    content: {
      type: Object as () => Flickity,
      default: {} as Flickity,
    },
  },
  setup() {
    let slider
    const list = ref<null | HTMLElement>(null)

    onMounted(async () => {
      // Flickity seems to use `window` when imported
      // which causes troubles server-side…
      // Require will add it to the bundle.
      // Import will lazy load it…
      // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
      // const Flickity = require('flickity')
      const Flickity = await import(
        /* webpackChunkName: 'vendor-flickity' */ 'flickity'
      ).then(module => module.default)

      slider = new Flickity(list.value as HTMLElement, {
        pageDots: false,
        prevNextButtons: true,
        transition() {
          return Promise.resolve()
        },
      })

      slider.on('select', () => {
        requestAnimationFrame(() => {
          slider.cells.forEach(cell => {
            if (
              cell.element.getBoundingClientRect().left <
              slider.selectedCell.element.getBoundingClientRect().left
            ) {
              cell.element.classList.add('lefted')
            } else {
              cell.element.classList.remove('lefted')
            }
          })
        })
      })
    })

    onUnmounted(() => {
      slider.destroy()
    })

    return {
      list,
    }
  },
})
