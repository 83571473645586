















































import { defineComponent } from '@vue/composition-api'
import { HomeMission } from '@/inc/types'
import KeyfactsGrid from '@/components/keyfacts/Grid.vue'

export default defineComponent({
  name: 'home-mission',
  props: {
    content: {
      type: Object as () => HomeMission,
      default: {} as HomeMission,
    },
  },

  components: {
    KeyfactsGrid,
  },
})
