















































import { ref, defineComponent } from '@vue/composition-api'
import { HomeTextBlock } from '@/inc/types'

export default defineComponent({
  name: 'home-text-block',
  props: {
    content: {
      type: Object as () => HomeTextBlock,
      default: {} as HomeTextBlock,
    },
  },

  setup() {
    const block = ref<HTMLElement | null>(null)
    const title = ref<HTMLElement | null>(null)
    const text = ref<HTMLElement | null>(null)

    return {
      block,
      title,
      text,
    }
  },
})
