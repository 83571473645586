


























import { defineComponent } from '@vue/composition-api'
import { Keypoints } from '@/inc/types'

import KeypointsItem from './Item.vue'

export default defineComponent({
  name: 'keypoints-grid',
  props: {
    content: {
      type: Array as () => Keypoints,
      default: [] as Keypoints,
    },
  },

  components: {
    KeypointsItem,
  },
})
