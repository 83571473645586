





































import { defineComponent } from '@vue/composition-api'
import { Keypoint } from '@/inc/types'

export default defineComponent({
  name: 'keypoints-item',
  props: {
    content: {
      type: Object as () => Keypoint,
      default: {} as Keypoint,
    },
  },
})
