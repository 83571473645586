












































import { defineComponent, onMounted } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'
import HomeDuo from '@/components/home/Duo.vue'
import HomeTeam from '@/components/home/team/Team.vue'
import HomeTextBlock from '@/components/home/TextBlock.vue'
import HomeMission from '@/components/home/Mission.vue'
import SliderFlickity from '@/components/slider/Flickity.vue'
import SliderSlidy from '@/components/slider/Slidy.vue'

import gsap from 'gsap'
import { SplitText } from '@/inc/vendor/gsap-extra/SplitText'
// REVIEW:
// import { SlowMo } from '@/inc/vendor/gsap-extra/EasePack'
import ready from '@/components/webgl/ready'
import lock from '@/inc/utils/lock'

gsap.registerPlugin(SplitText)

export default defineComponent({
  name: 'VHome',
  data() {
    return {
      // prettier-ignore
      /* eslint-disable */
    }
  },
  components: {
    HomeDuo,
    HomeTeam,
    HomeMission,
    HomeTextBlock,
    SliderFlickity,
    SliderSlidy,
  },
  setup(props, ctx) {
    const prevRoute = useGetters(['prevRoute'])
    let isOnboarding = false

    const onBoarding = () => {
      isOnboarding = true
      window.scrollTo(0, 0)
      lock.lock()

      ready.ready.then(webgl => {
        const tl = gsap.timeline({
          delay: 0.4,
        })

        const header = document.querySelector('.header')
        const home = document.querySelector('.home')
        const headline = document.querySelector('.hero__headline')
        const title = document.querySelector('.hero__title')

        const splitHeadline = new SplitText(headline, {
          type: 'words',
        })

        const splitTitle = new SplitText(title, {
          type: 'words',
        })

        tl.set(header, {
          opacity: 0,
        })
        tl.set(home, {
          opacity: 0,
        })
        tl.to(
          header,
          {
            opacity: 1,
            duration: 2,
            ease: 'power4.out',
          },
          0
        )
        tl.to(
          home,
          {
            opacity: 1,
            duration: 2,
            ease: 'power4.out',
          },
          0
        )
        tl.from(
          webgl.scene.grid.rotation,
          {
            x: -1.2,
            duration: 4,
            ease: 'power4.out',
          },
          1.2
        )

        tl.from(
          webgl.scene.grid.material.uniforms.force,
          {
            value: 10,
            duration: 4,
            ease: 'power4.out',
          },
          1.2
        )

        tl.from(
          splitHeadline.words,
          {
            opacity: 0,
            stagger: 0.1,
            duration: 1,
          },
          0.2
        )

        tl.from(
          headline,
          {
            scale: 1.4,
            y: 100,
            duration: 2,
            ease: 'power4.out',
          },
          1.4
        )

        tl.from(
          splitTitle.words,
          {
            opacity: 0,
            y: 10,
            stagger: 0.1,
            duration: 0.5,
            ease: 'power4.out',
            force3D: true,
          },
          2
        )

        tl.from(
          webgl.scene.wave.material.uniforms.frequency,
          {
            value: 5,
            duration: 3,
            ease: 'power2.out',
          },
          2.5
        )

        tl.from(
          '.header',
          {
            yPercent: -100,
            duration: 1.5,
            ease: 'power4.out',
          },
          4
        )

        tl.from(
          ['.home-text-block', '.home-mission'],
          {
            opacity: 0,
            y: 200,
            duration: 1,
            ease: 'power4.out',
          },
          4.3
        )

        tl.add(() => {
          lock.unlock()
          isOnboarding = false
        }, 4.2)
      })
    }

    const onAppear = {
      team(isInViewport, isFullyInViewport, position) {
        if (isOnboarding) {
          return
        }

        ready.ready.then(webgl => {
          const tl = gsap.timeline()

          gsap.killTweensOf([
            webgl.scene.wave.material.uniforms.uColor.value,
            webgl.scene.wave.material.uniforms.uOffset.value,
            webgl.scene.wave.material.uniforms.force,
          ])

          tl.to(
            webgl.scene.wave.material.uniforms.uColor.value,
            {
              r: isInViewport ? 240 / 255 : 49 / 255,
              g: isInViewport ? 244 / 255 : 122 / 255,
              b: isInViewport ? 246 / 255 : 249 / 255,
              duration: 1,
              ease: 'power4.out',
            },
            0
          )

          tl.to(
            webgl.scene.wave.material.uniforms.force,
            {
              value: 0,
              duration: 0.5,
              onComplete: () => {
                webgl.scene.wave.material.uniforms.uDraw.value = false
                webgl.scene.wave.material.uniforms.uOffset.value.set(
                  isInViewport ? -0.3 : 0,
                  isInViewport ? -0.1 : 0.1,
                  0
                )
                webgl.scene.wave.material.uniforms.uDraw.value = true

                gsap.fromTo(
                  webgl.scene.wave.material.uniforms.force,
                  {
                    value: 0,
                  },
                  {
                    value: 0.237,
                    duration: 3,
                  }
                )
              },
            },
            0
          )
        })
      },
    }

    onMounted(() => {
      if (!prevRoute.prevRoute.value) {
        onBoarding()
      }
    })

    return {
      ...useGetters(['content']),
      onAppear,
    }
  },
})
