


























































































import { defineComponent } from '@vue/composition-api'
import { HomeTeam } from '@/inc/types'

import KeypointsGrid from '@/components/keypoints/Grid.vue'
import TeamSlidy from './TeamSlidy.vue'

export default defineComponent({
  name: 'home-team',
  props: {
    content: {
      type: Object as () => HomeTeam,
      default: {} as HomeTeam,
    },
  },

  components: {
    KeypointsGrid,
    TeamSlidy,
  },
})
