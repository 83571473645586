


























































































































































































import {
  defineComponent,
  ref,
  onMounted,
  onUnmounted,
} from '@vue/composition-api'
import { gsap } from 'gsap'
import Slidy from 'epic-slidy'
import { Slide } from '@/inc/types'

export default defineComponent({
  name: 'team-slidy',
  props: {
    slides: {
      type: Array as () => Slide[],
      default: [],
    },
  },

  setup(props) {
    let slider: Slidy
    const list = ref<null | HTMLElement>(null)
    const picture = ref<null | HTMLElement>(null)
    const pictures = ref<null | HTMLElement>(null)
    const progress = ref<null | HTMLElement>(null)
    const perChunk = 6
    // REVIEW: check types…
    const slideGroups = props.slides.reduce((acc, slide, index) => {
      const chunkIndex = Math.floor(index / perChunk)

      if (!acc[chunkIndex]) {
        acc[chunkIndex] = [] // Start a new chunk
      }

      acc[chunkIndex].push(slide)

      return acc
    }, [] as Slide[][])

    onMounted(() => {
      const updateProgress = () => {
        if (props.slides.length <= 6) {
          return
        }
        const progressCircle = {
          x: '600',
        }

        if (progress.value) {
          reset()
          gsap.to(progressCircle, 10, {
            x: 0,
            onUpdate: () => {
              if (progress.value) {
                progress.value.style.strokeDashoffset = progressCircle.x
              }
            },
            ease: 'linear',
          })
        }
      }

      const reset = () => {
        if (!progress.value) {
          return
        }
        gsap.killTweensOf(progress.value)
        gsap.set(progress.value, { clearProps: 'all' })
      }

      const transition = (currentSlide: HTMLElement, newSlide: HTMLElement) => {
        // REVIEW: returns gsap promise directly
        const tl = gsap.timeline({
          onComplete: () => {
            currentSlide.style.zIndex = '1'
            newSlide.style.zIndex = '3'
          },
        })
        updateProgress()

        const current = {
          /* eslint-disable */
          pictures: currentSlide.querySelectorAll('.picture-inner'),
          /* eslint-enable */
        }

        const next = {
          /* eslint-disable */
          pictures: newSlide.querySelectorAll('.picture-inner'),
          /* eslint-enable */
        }

        return tl
          .add('transition')
          .set(newSlide, {
            zIndex: 4,
            opacity: 1,
          })
          .set(next.pictures, {
            scale: 1.2,
            opacity: 0,
          })

          .to(
            next.pictures,
            {
              duration: 0.2,
              opacity: 1,
              stagger: 0.05,
              scale: 1,
              ease: 'power4',
            },
            'transition'
          )
          .to(
            current.pictures,
            {
              duration: 0.2,
              opacity: 0,
              stagger: 0.05,
              scale: 1.2,
              ease: 'power4',
            },
            'transition'
          )
          .then()
      }

      slider = new Slidy(list.value as HTMLElement, {
        click: true,
        auto: true,
        loop: true,
        interval: 10000,
        transition,
      })
      updateProgress()
      slider.init()
    })

    onUnmounted(() => {
      slider.destroy()
    })

    return {
      slideGroups,
      list,
      picture,
      pictures,
      progress,
    }
  },
})
